export const API_BASEURL = process.env.VUE_APP_API_BASEURL
    ? process.env.VUE_APP_API_BASEURL
    : "http://localhost:8000/api"
export const URL = process.env.VUE_APP_URL
    ? process.env.VUE_APP_URL
    : "http://localhost:8000/"

export const WEEKDAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
]

export const FORMAT_SIGNATURES_ACCEPETD = [
    ".png",
    ".jpg",
    ".jpeg"
]

export const CATEGORY_MAP = {
    1: "Communications",
    2: "Computer equipment",
    3: "Food",
    4: "Fuel",
    5: "Parking",
    6: "Taxi",
    7: "Technical books",
    8: "Toll",
    9: "Transportation",
    10: "Other, check description"
};