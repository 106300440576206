import { $themeConfig } from "@themeConfig"

export default {
    namespaced: true,
    state: {
        isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    },
    getters: {},
    mutations: {
        UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
            state.isVerticalMenuCollapsed = val
        },
    },
    actions: {},
}
