import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"

// Modules
import app from "./app"
import appConfig from "./app-config"
import verticalMenu from "./vertical-menu"
import portal from "./portal"

Vue.use(Vuex)

const vuexlocal = new VuexPersistence({
    storage: window.localStorage,
})

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        portal,
    },
    plugins: [vuexlocal.plugin],
})
