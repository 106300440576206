import * as Constants from "@/store/constants"

import axios from "axios"
import store from "@/store"
import router from "@/router"

import Auth from "@/api/services/Auth.js"

import { asyncLoading } from "vuejs-loading-plugin"

// import Swal from 'sweetalert2'

export let cancelToken = null
export const cancel = () => {
    if (cancelToken) {
        // store.commit(Constants.MUTATION_SET_LOADING, false)
        cancelToken.cancel()
    }
}

export const http = (auth, base = `${Constants.API_BASEURL}v1`) => {
    const requester = axios.create({
        baseURL: base,
    })

    if (auth) {
        requester.defaults.headers.Authorization = `Bearer ${store.state.portal.login.access}`
    }

    // refresh token
    let failedQueue = []

    const processQueue = (error, token = null) => {
        failedQueue.forEach((prom) => {
            if (error) {
                prom.reject(error)
            } else {
                prom.resolve(token)
            }
        })
        failedQueue = []
    }
    // end refresh

    cancel()

    // Add a request interceptor
    requester.interceptors.request.use(
        (config) => {
            cancelToken = axios.CancelToken.source()
            config.cancelToken = cancelToken.token

            return config
        },
        (error) => Promise.reject(error)
    )

    // Add a response interceptor
    requester.interceptors.response.use(
        (response) => {
            // Do something with response data
            const result = Promise.resolve(response)
            return asyncLoading(result).then().catch()
        },
        (error) => {
            switch (error?.response?.status) {
                case 401:
                    if (auth) {
                        const originalRequest = error.config
                        originalRequest._retry = true
                        const refreshToken = {
                            refresh: store.state.portal.login.refresh,
                        }

                        if (
                            error.config.url === "/auth/token/refresh/" ||
                            !store.state.portal.login.refresh
                        ) {
                            store.commit("portal/logout")
                            const result = Promise.reject(error)
                            return asyncLoading(result).then().catch()
                        }

                        const resultRefresher = new Promise(
                            (resolve, reject) => {
                                requester
                                    .post("/auth/token/refresh/", refreshToken)
                                    .then((response) => {
                                        store.commit(
                                            "portal/setLogin",
                                            response.data
                                        )
                                        requester.defaults.headers.common.Authorization = `Bearer ${store.state.portal.login.access}`
                                        originalRequest.headers.Authorization = `Bearer ${store.state.portal.login.access}`
                                        processQueue(
                                            null,
                                            store.state.portal.login.access
                                        )
                                        resolve(requester(originalRequest))
                                    })
                                    .catch((error) => {
                                        processQueue(error, null)
                                        router.push("/")
                                        const result = Promise.reject(error)
                                        return asyncLoading(result)
                                            .then()
                                            .catch()
                                    })
                            }
                        )
                        return resultRefresher
                    }
                    const result = Promise.reject(error)
                    return asyncLoading(result).then().catch()

                case 403:
                    if (auth) {
                        const originalRequest = error.config
                        originalRequest._retry = true
                        const refreshToken = {
                            refresh: store.state.portal.login.refresh,
                        }

                        if (
                            error.config.url === "/auth/token/refresh/" ||
                            !store.state.portal.login.refresh
                        ) {
                            store.commit("portal/logout")
                            const result = Promise.reject(error)
                            return asyncLoading(result).then().catch()
                        }

                        const resultRefresher = new Promise(
                            (resolve, reject) => {
                                requester
                                    .post("/auth/token/refresh/", refreshToken)
                                    .then((response) => {
                                        store.commit(
                                            "portal/setLogin",
                                            response.data
                                        )
                                        requester.defaults.headers.common.Authorization = `Bearer ${store.state.portal.login.access}`
                                        originalRequest.headers.Authorization = `Bearer ${store.state.portal.login.access}`
                                        processQueue(
                                            null,
                                            store.state.portal.login.access
                                        )
                                        resolve(requester(originalRequest))
                                    })
                                    .catch((error) => {
                                        processQueue(error, null)
                                        router.push("/")
                                        return Promise.reject(error)
                                    })
                            }
                        )
                        return resultRefresher
                    }
                    return Promise.reject(error)

                default:
                    if (!(error == "Cancel"))
                        return asyncLoading(Promise.reject(error))
                            .then()
                            .catch()
            }
        }
    )
    return requester
}
export const https = (auth, base = `${Constants.API_BASEURL}v1`) => {
    const requester = axios.create({
        baseURL: base,
    })

    if (auth) {
        requester.defaults.headers.Authorization = `Bearer ${store.state.portal.login.access}`
    }

    // refresh token
    let failedQueue = []

    const processQueue = (error, token = null) => {
        failedQueue.forEach((prom) => {
            if (error) {
                prom.reject(error)
            } else {
                prom.resolve(token)
            }
        })
        failedQueue = []
    }
    // end refresh

    cancel()

    // Add a request interceptor
    requester.interceptors.request.use(
        (config) => {
            cancelToken = axios.CancelToken.source()
            config.cancelToken = cancelToken.token

            return config
        },
        (error) => Promise.reject(error)
    )

    // Add a response interceptor
    requester.interceptors.response.use(
        (response) => {
            // Do something with response data
            const result = Promise.resolve(response)
            return asyncLoading(result).then().catch()
        },
        (error) => {
            switch (error?.response?.status) {
                case 401:
                    if (auth) {
                        const originalRequest = error.config
                        originalRequest._retry = true
                        const refreshToken = {
                            refresh: store.state.portal.login.refresh,
                        }

                        if (
                            error.config.url === "/auth/token/refresh/" ||
                            !store.state.portal.login.refresh
                        ) {
                            store.commit("portal/logout")
                            const result = Promise.reject(error)
                            return asyncLoading(result).then().catch()
                        }

                        const resultRefresher = new Promise(
                            (resolve, reject) => {
                                requester
                                    .post("/auth/token/refresh/", refreshToken)
                                    .then((response) => {
                                        store.commit(
                                            "portal/setLogin",
                                            response.data
                                        )
                                        requester.defaults.headers.common.Authorization = `Bearer ${store.state.portal.login.access}`
                                        originalRequest.headers.Authorization = `Bearer ${store.state.portal.login.access}`
                                        processQueue(
                                            null,
                                            store.state.portal.login.access
                                        )
                                        resolve(requester(originalRequest))
                                    })
                                    .catch((error) => {
                                        processQueue(error, null)
                                        router.push("/")
                                        return Promise.reject(error)
                                    })
                            }
                        )
                        return resultRefresher
                    }
                    return Promise.reject(error)

                case 403:
                    if (auth) {
                        const originalRequest = error.config
                        originalRequest._retry = true
                        const refreshToken = {
                            refresh: store.state.portal.login.refresh,
                        }

                        if (
                            error.config.url === "/auth/token/refresh/" ||
                            !store.state.portal.login.refresh
                        ) {
                            store.commit("portal/logout")
                            const result = Promise.reject(error)
                            return asyncLoading(result).then().catch()
                        }

                        const resultRefresher = new Promise(
                            (resolve, reject) => {
                                requester
                                    .post("/auth/token/refresh/", refreshToken)
                                    .then((response) => {
                                        store.commit(
                                            "portal/setLogin",
                                            response.data
                                        )
                                        requester.defaults.headers.common.Authorization = `Bearer ${store.state.portal.login.access}`
                                        originalRequest.headers.Authorization = `Bearer ${store.state.portal.login.access}`
                                        processQueue(
                                            null,
                                            store.state.portal.login.access
                                        )
                                        resolve(requester(originalRequest))
                                    })
                                    .catch((error) => {
                                        processQueue(error, null)
                                        router.push("/")
                                        return Promise.reject(error)
                                    })
                            }
                        )
                        return resultRefresher
                    }
                    return Promise.reject(error)

                default:
                    if (!(error == "Cancel")) return Promise.reject(error)
            }
        }
    )
    return requester
}

export default {
    Auth,
    http,
    https,
    cancel,
}
