import Vue from "vue"
import { ToastPlugin, ModalPlugin } from "bootstrap-vue"
import VueCompositionAPI from "@vue/composition-api"

import VueLoading from "vuejs-loading-plugin"
import VueTour from "vue-tour"

import moment from "moment"
import L from "leaflet"
import "leaflet/dist/leaflet.css"

import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png"
import iconUrl from "leaflet/dist/images/marker-icon.png"
import shadowUrl from "leaflet/dist/images/marker-shadow.png"

import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"

import VueExcelViewer from "@uublue/vue-excel-viewer"
import "@uublue/vue-excel-viewer/lib/vue-excel-viewer.css"

import PrimeVue from "primevue/config"
import App from "./App.vue"
import store from "./store"
import router from "./router"

// Global Components
import "./global-components"

// 3rd party plugins
import "@/libs/portal-vue"
import "@/libs/toastification"

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
})

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueLoading)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueTour)
Vue.use(VueExcelViewer)

Vue.use(PrimeVue)

Vue.filter("currency", (value) => {
    if (!value) {
        return ""
    }
    return `${parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")} €`
})

Vue.filter("formatDate", (value) => {
    if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm")
    }
})
Vue.filter("myFormatDate", (value) => {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY")
    }
})

Vue.filter("monthYearFormat", (value) => {
    if (value) {
        return moment(String(value)).format("MMMM YYYY")
    }
})
Vue.filter("monthYearPeopleFormat", (value) => {
    const d = value.split("-")
    if (value) {
        return moment(new Date(d[2], d[1] - 1, 1)).format("MMMM YYYY")
    }
})

Vue.filter("amountFilter", (value) => Number(value).toFixed(2))

// import core styles
require("@core/scss/core.scss")

// import assets styles
require("@/assets/scss/style.scss")

// vue-tour
require("vue-tour/dist/vue-tour.css")

Vue.config.productionTip = false

if (process.env.VUE_APP_URL !== "http://localhost:8000/") {
    Sentry.init({
        Vue,
        dsn: "https://7b0495ae5ef843549720177ff7600bac@o1287173.ingest.sentry.io/4504244842790912",
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: [
                    "finance.damiagroup.pt",
                    "portal.damiagroup.pt",
                    "portal.wearemeta.io",
                    /^\//,
                ],
            }),
        ],
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        integrations: [new Sentry.Replay()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: "production",
    })
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app")
