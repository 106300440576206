import { $themeConfig } from "@themeConfig"

export default {
    namespaced: true,
    state: {
        login: {},
        profile: {},
        kmWeekend: false,
    },
    getters: {},
    mutations: {
        setLogin(state, data) {
            state.login = data
        },
        setProfile(state, data) {
            state.profile = data
        },
        setKmWeekend(state, data) {
            state.kmWeekend = data
        },
        logout(state) {
            Object.assign(state, {
                login: {},
                profile: {},
                kmWeekend: false,
            })
        },
    },
    actions: {},
}
